.productHeader {
    align-items: stretch;
    width: 100%;
    color: var(--color--light);
    background: var(--color--primary);

    .productHeader__content {
        display: flex;
        grid-column-start: large-start;
        grid-column-end: center-left;
        align-items: center;
        position: relative;
        padding: var(--spacing--3xl) 0;

        .product__toggleShops {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: var(--text--lg);
            color: var(--color--light);
            background: var(--color--pink);
            padding: var(--spacing--xs) var(--spacing--md);
            border-radius: 5rem;
            cursor: pointer;
            transform: scale(1) translateY(50%);
            transition: transform 0.2s ease-in-out;
            gap: var(--spacing--2xs);

            &:hover {
                transform: scale(1.05) translateY(50%);
            }
        }

        svg.svg__round {
            display: none;

            @media (--media--md) {
                display: block;
                width: auto;
                height: 100%;
                position: absolute; 
                top: 0;
                bottom: 0;
                right: 0;
                z-index: 1;
                transform: translateX(calc(100% - 1px));
                fill: var(--color--primary);
            }
        }
    }

    .productHeader__media {
        display: flex;
        align-items: stretch;
        order: 1;
        grid-column: page;

        @media (--media--md) {
            grid-column-start: center-left;
            grid-column-end: page-end;
            order: 2;
        }

        .media {
            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
