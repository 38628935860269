.link {
    display: flex;
    align-items: center;
    gap: var(--spacing--2xs);

    &:hover {
        color: var(--color--pink);
    }

    .link__icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .link__content {
        display: flex;
        flex-direction: column;
    }
    .link__title {
        font-size: var(--text--md);
        font-weight: 700;
    }

    .link__description {
        font-size: var(--text--xs);
        font-weight: 400;
    }

    .link__additional {
        text-transform: uppercase;
        margin-left: var(--spacing--2xs);
        align-self: flex-start;
    }
}

.bg--pink {
    .link:hover {
        color: var(--color--green) !important;
    }
}