.accordion {
    width: 100%;

    .accordion__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--spacing--sm);
    }
}
