.quicklinks {
    --size: 45px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    top: 90%;
    right: 0;
    z-index: 9998;
    padding-right: var(--spacing--2xs);
    transform: translate(0, -100%);
    gap: var(--spacing--2xs);

    @media (--media--sm) {
        --size: 64px;
        top: 50%;
        transform: translate(0, -50%);
    }

    .quicklink {
        display: flex;;
        justify-content: center;;
        align-items: stretch;
        width: auto;
        position: relative;
        overflow: hidden;
        border-radius: 5rem;
        cursor: pointer;

        &:hover {
            overflow: visible;
            
            .quicklink__label {
                opacity: 1;
            }
            .quicklink__icon {
                background: var(--color--pink);
            }
        }

        &__label {
            display: flex;;
            justify-content: center;;
            align-items: center;
            opacity: 1;
            width: auto;
            height: var(--size);
            position: absolute;
            right: 0;
            z-index: 0;
            background: var(--color--primary-light);
            padding-right: calc(var(--spacing--xs) + 64px);
            padding-left: var(--spacing--xs);
            border-radius: 0 5rem 5rem 0;
            pointer-events: none;
            transition: all 0.3s ease-in-out;
        }

        &__icon {
            display: flex;;
            justify-content: center;;
            align-items: center;
            width: var(--size);
            height: var(--size);
            position: relative;
            z-index: 10;
            color: var(--color--light);
            background: var(--color--primary);
            border-radius: 5rem;
        }
    }
}
