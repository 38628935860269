html {
	font-size: var(--font--base-size);
	scroll-behavior: smooth;
	scroll-padding-top: var(--spacing--lg);

    @media (--media--sm) {
        scroll-padding-top: var(--spacing--xl);
    }
}


body {
    max-width: 100vw;
	font-family: var(--font--base-family);
	font-size: var(--font--base-size);
    line-height: var(--leading--normal);
	font-weight: 400;
	color: var(--color--font-base);
	background: var(--color--light);
	overflow-x: hidden;
	overflow-y: auto;
}


.neos-backend {
	.neos-contentcollection {
		& > div {
			width: 100%;
		}
	}
}

::selection {
	text-shadow: none;
	color: var(--color--light);
	background: var(--color--pink);
}

.sr-only {
    width: 0;
    height: 0;
    position: absolute;
    overflow: hidden;
}
