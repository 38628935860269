.pageCardSlider {
    width: 100%;
    max-width: 100vw;
    position: relative;
    padding: var(--spacing--2xl) 0;
    overflow: hidden;

    >.media {
        width: 100%;
        position: absolute;
        z-index: -10;
        inset: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            aspect-ratio: 1/1;
        }
    }

    .headline {
        color: var(--color--light);
        grid-column: large;
    }

    .pageCardSlider__content {
        width: 100%;
        grid-column: large;

        @media (max-width: 767px) {
            padding: 0 var(--spacing--md);
            overflow: hidden;
            grid-column: page;
        }
    }

    .pageCardSlider__controls {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: var(--spacing--sm);
        grid-column: large;
        gap: var(--spacing--sm);

        @media (--media--md) {
            grid-column-start: large-start;
            grid-column-end: center-left;
        }
    }

    .swiper-buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: -10px;
        gap: var(--spacing--3xs);

        .swiper-button-prev, .swiper-button-next {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            color:  var(--color--light);
            cursor: pointer;

            &:hover {
                opacity: 0.5;
            }

            svg {
                width: 36px;
                height: 36px;
            }

            &.swiper-button-disabled {
                opacity: 0.25;
                cursor: default;

                &:hover {
                    opacity: 0.2;
                }
            }
        }
    }

    .swiper-pagination {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: var(--spacing--3xs);

        .swiper-pagination-bullet {
            opacity: 0.5;
            width: 12px;
            height: 12px;
            background: var(--color--light);
            border-radius: 100%;
            cursor: pointer;

            &-active {
                opacity: 1;
            }
        }
    }

    .swiper-wrapper {
        height: auto;
    }
}
