.productOutputItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    border-radius: var(--radius--sm);

    .media {
        width: 100%;

        img {
            object-fit: cover;
            object-position: center;
            aspect-ratio: 1/1;
        }
    }

    &__title {
        flex-grow: 1;
        width: 100%;
        background: var(--color--primary-lightest);
        padding: var(--spacing--sm);
        text-align: center;
    }
}
