.navigation {
    display: flex;
    align-items: stretch;
    height: 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--color--light);
    overflow: hidden;    

    @media (--media--lg) {
        height: initial !important;
        position: unset;
        top: initial;
        left: initial;
        right: initial;
        overflow: unset;
        transition: none;
    }

    @media (max-width: 991px) {
        &.active {
            height: calc(100dvh - 97px);
            padding-top: var(--spacing--xl);
            overflow-y: auto;
            
            .navigation__item:last-child {
                padding-bottom: var(--spacing--xl);
            }
        }
    }

    &__content {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        width: 100%;
        z-index: 10;

        @media (--media--lg) {
            flex-direction: row;
            align-items: stretch;
            width: auto;
            height: auto;
            position: static;
            left: 0;
            z-index: 0;
            background: none;
            padding: 0;
            pointer-events: all;
        }

        .navigation__item {
            list-style: none;
            display: flex;
            flex-direction: column;
            width: 100%;
            position: relative;

            @media (--media--lg) {
                align-items: stretch;
                width: auto;

                &:hover {
                    > a {
                        font-weight: 700;
                        &:after {
                            opacity: 1;
                            width: 30px;
                        }
                    }
                    > span {
                        svg {
                            stroke: var(--color--pink);
                        }
                    }

                    .navigation__sub {
                        opacity: 1;
                        pointer-events: all;
                        transform: translateX(0) translateY(100%);
                    }
                }

                &.active {
                    > a {
                        font-weight: 700;

                        &:after {
                            opacity: 1;
                            width: 30px;
                        }
                    }
                }
            }

            &:hover {
                 > a {
                    font-weight: 700;
                }
                > span {
                    svg {
                        stroke: var(--color--pink);
                    }
                }
            }

            &.active {
                > a {
                    font-weight: 700;
                }
            }

            > a, > span {
                display: flex;
                align-items: center;
                width: 100%;
                position: relative;
                font-size: var(--text--lg);
                padding: var(--spacing--2xs) var(--spacing--md);
                cursor: pointer;

                @media (--media--lg) {
                    width: auto;
                    height: 97px;
                    padding: var(--spacing--md) var(--spacing--md);

                    &:after {
                        content: '';
                        opacity: 0;
                        width: 0;
                        height: 5px;
                        position: absolute;
                        bottom: 0;
                        left: var(--spacing--md);
                        background: var(--color--primary);
                        transition: all 0.35s ease-in-out;
                    }
                }
            }

            .navigation__sub {
                width: 100%;
                padding: 0 var(--spacing--md);
                margin-top:  var(--spacing--4xs);
                margin-bottom:  var(--spacing--xs);

                @media (--media--lg) {
                    opacity: 0;
                    width: 225px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index:100;
                    background: var(--color--primary-light);
                    padding:  var(--spacing--2xs) 0;
                    margin: 0;
                    pointer-events: none;
                    transform: translateX(-20px) translateY(100%);
                    transition: all 0.35s ease-in-out;
                }

                .navigation__sub__item {
                    list-style: none;
                    display: block;

                    &.active {
                        a {
                            font-weight: 700;
                        }
                    }

                    a {
                        display: block;
                        width: 100%;
                        font-weight: 400;
                        padding: var(--spacing--2xs)  var(--spacing--md);

                        &:hover {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    &__toggle {
        display: inline-block;
        width: 2.25rem;
        padding: 0.25rem 0;
        cursor: pointer;
        touch-action: manipulation;

        @media (hover: hover) {
            &:hover {
                span:nth-child(3) {
                    width: 100%;
                }
            }
        }

        @media (--media--lg) {
            display: none;
        }

        span {
            display: block;
            width: 100%;
            height: 3px;
            background: var(--color--primary);
            border-radius: var(--radius--md);
            transition: all 0.25s var(--easing--in-out);

            &:first-child {
                transform: translateY(0) rotate(0);
            }

            &:nth-child(2) {
                margin-top: 5px;
            }

            &:last-child {
                width: 60%;
                margin-top: 5px;
                margin-right: auto;
                transform: translateY(0) rotate(0);
            }
        }

        &.active {
            span {
                &:first-child {
                    transform: translateY(8px) rotate(45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                    width: 0;
                }

                &:last-child {
                    width: 100%;
                    transform: translateY(-8px) rotate(-45deg);
                }
            }
        }
    }
}
