.bg--primary {
    background: var(--color--primary);
}

.bg--primaryLight {
    background: var(--color--primary-lightest);
}

.bg--pink {
    background: var(--color--pink);
}

.bg--green {
    background: var(--color--green);
}
