.links {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing--xs);

    &.direction--column {
        .neos-contentcollection {
        flex-direction: column;
        }
    }

    &.direction--row {
        .neos-contentcollection {
        flex-direction: row;
        }
    }

    .neos-contentcollection {
        display: flex;
        gap: var(--spacing--xs);
        flex-wrap: wrap;

    }
}
