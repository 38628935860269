.footer {
    width: 100%;
    background: var(--color--primary-lightest);
    padding: var(--spacing--xl) 0;
    margin-top: var(--spacing--4xl);

    .footer__inner {
        display: grid;
        grid-template-columns: 1fr;
        align-items: flex-start;
        gap: var(--spacing--xl);


        @media (--media--md) {
            grid-template-columns: repeat(2, 1fr);
        }


        @media (--media--lg) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .footer__logo {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        @media (--media--md) {
            width: auto;
        }

        img {
            width: auto;
            height: 100%;
            max-height: 120px;
        }
    }

    .paragraph {
            text-align: left;
    }

    .footer__navigation {
        width: 100%;
        ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2px;

            &.classic {
                a {
                    font-weight: 700;;
                }
            }

            &.service {
                margin-top: var(--spacing--2xs);
            }

            .navigation__item {
                width: auto;
                &:hover {
                    > a {
                        color: var(--color--pink);
                    }
                }
                &.active {
                    > a {
                        color: var(--color--pink);
                    }
                }

                a {
                    width: auto;
                }
            }
        }
    }

    .footer__social {
        display: block;

        .neos-contentcollection {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: var(--spacing--sm);
                @media (--media--md) {
                    justify-content: flex-end;
                }

            .socialIcon {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 36px;
                    height: auto;
                }
            }
        }
    }
}
