.accordionItem {
    width: 100%;

    &.has-titleIcon {
        button.accordionItem__button {
            grid-template-columns: 1fr 5fr 40px;

            @media (--media--sm) {
                grid-template-columns: 87px 1fr 56px;
            }
        }
    }

    button.accordionItem__button {
        display: grid;
        grid-template-columns: 5fr 40px;
        align-items: center;
        width: 100%;
        color: var(--color--primary);
        background: var(--color--primary-lightest);
        overflow: hidden;
        border-radius: var(--radius--sm);

        @media (--media--sm) {
            grid-template-columns: 1fr 56px;
        }

        .title__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            background: var(--color--primary-light);
            padding: var(--spacing--xs);

            img {
                width: 100%;
                height: auto;
                object-fit: contain;
                object-position: center;
                aspect-ratio: 1 / 1;
            }
        }

        .title__content {
            display: block;
            font-size: var(--text--lg);
            font-weight: 700;
            padding: var(--spacing--xs);
            text-align: left;

            @media (--media--sm) {
                padding: var(--spacing--md);
            }
        }

        .accordionItem__icon__container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: auto;
            position: relative;

            svg {
                width: 36px;
                height: 36px;
                transform: rotate(0deg);
                transition: transform 0.3s ease-in-out;
                stroke: var(--color--primary);
                stroke-width: 2;
                fill: none;
                stroke-linecap: round;
                stroke-linejoin: round;
            }
        }
    }

    .accordionItem__content {
        display: grid;
        grid-template-rows: 0fr;
        margin-top: 0;
        transition: grid-template-rows 0.5s var(--easing--in-out);


        .accordionItem__content-inner {
            padding: 0 var(--spacing--md);
            overflow: hidden;
            transition: padding 0.5s var(--easing--in-out);
        }
    }

    &.active {
        button {
            color: var(--color--light);
            background: var(--color--primary);

            .accordionItem__icon__container {
                svg {
                    transform: rotate(45deg);
                    stroke: var(--color--light);
                }
            }
        }

        .accordionItem__content {
            grid-template-rows: 1fr;

            .accordionItem__content-inner {
                padding: var(--spacing--md);
            }
        }
    }
}
