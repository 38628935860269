.tiles {
    width: 100%;

    &.area-\[large\] {
        &.columns-4 {
            .tiles__content {
                @media (--media--md) {
                    grid-template-columns: repeat(2, 1fr);
                }
                @media (--media--lg) {
                    grid-template-columns: repeat(3, 1fr);
                }
                @media (--media--xl) {
                    grid-template-columns: repeat(4, 1fr);
                }
            }
        }
        &.columns-3 {
            .tiles__content {
                @media (--media--md) {
                    grid-template-columns: repeat(2, 1fr);
                }
                @media (--media--lg) {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }
        &.columns-2 {
            .tiles__content {
                @media (--media--md) {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
    }

    &.area-\[medium\] {
        &.columns-3, &.columns-4 {
            .tiles__content {
                @media (--media--md) {
                    grid-template-columns: repeat(2, 1fr);
                }
                @media (--media--xl) {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }
        &.columns-2 {
            .tiles__content {
                @media (--media--md) {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
    }

    &.area-\[small\] {
        &.columns-2, &.columns-3, &.columns-4 {
            .tiles__content {
                @media (--media--lg) {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
    }

    .tiles__content {
        width: 100%;
        display: grid;
        gap: var(--spacing--sm);
        grid-template-columns: 1fr;

        @media (--media--md) {
             gap: var(--spacing--md);
        }
    }
}
