.tilesItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--color--light);
    overflow: hidden;
    border-radius: var(--radius--sm);

    &.bg--primaryLight, &.bg--none {
        color: var(--color--primary);
    }

    .media {
        justify-content: center;
        align-items: stretch;
        width: 100%;

        img {
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: var(--spacing--md);
    }
}
