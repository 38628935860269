.paragraph {
    width: 100%;
    line-height: var(--leading--normal);
    column-count: 1;
    column-gap: var(--spacing--xl);
    column-rule-width: 1px;
    column-rule-style: solid;
    column-rule-color: var(--color--primary-light);

    p {
        hyphens: auto !important;
        hyphenate-limit-chars: 4 2 4;
    }

    p + p {
        margin-top: var(--spacing--2xs);
    }

    ul {
        list-style: none;
        li {
            position: relative;
            padding-left: 24px;
            &::before {
                content: '◼';
                position: absolute !important;
                left: -3px;
            }
        }
    }

    figure.table {
        width: 100%;

        table {
            width: 100%;
            border-collapse: collapse;

            td {
                border: 1px solid var(--color--primary-light);
                padding: var(--spacing--2xs);
            }
        }
    }

    &.columns-2 {
        @media (--media--sm) {
            column-count: 2;
        }
    }

    &.columns-3 {
        @media (--media--sm) {
            column-count: 2;
        }

        @media (--media--md) {
            column-count: 3;
        }
    }

    &.columns-4 {
        @media (--media--sm) {
            column-count: 2;
        }

        @media (--media--md) {
            column-count: 3;
        }

        @media (--media--lg) {
            column-count: 4;
        }
    }
}

.area-\[small-inner\] {
    .paragraph {
        &.columns-2 {
            column-count: 1;

            @media (--media--lg) {
                column-count: 2;
            }
        }
    }
}
