.modal {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    top: -100%;
    left: 0;
    z-index: 8888;
    overflow-y: scroll;
    pointer-events: none;
    transition: opacity 0.35s ease-in-out;
    backdrop-filter: blur(10px);
    
    &.active {
        opacity: 1;
        top: 0;
        pointer-events: all;
    }

    &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    &__background-close {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        background: rgba(255,255,255,0.5);
        inset: 0;
    }

    &__window {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        position: relative;

        &__close {
            position: absolute;
            bottom: 0;
            font-weight: bold;
            color: var(--color--light);
            background: var(--color--primary);
            padding: var(--spacing--xs) var(--spacing--sm);
            margin-bottom: calc(var(--spacing--md) * -2.5);
            border-radius: 5rem;
        }
    }
}

.modal__shops {
    display: grid;
    grid-template-columns: 1fr;

    @media (--media--lg) {
        grid-template-columns: repeat(2, 1fr);
    }

    .shops__card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 35vh;
        z-index: 1;
        padding: var(--spacing--md);

        @media (--media--lg) {
            padding: var(--spacing--xl);

            &--private {
                border-radius: var(--radius--sm) 0 0 var(--radius--sm);
            }
    
            &--company {
                border-radius: 0 var(--radius--sm) var(--radius--sm) 0;
            }
        }

        .lead, .headline, .paragraph, .link {
            color: var(--color--light);
        }

        &--private {
            background: var(--color--pink);
            border-radius: var(--radius--sm) var(--radius--sm) 0 0;
        }

        &--company {
            background: var(--color--green);
            border-radius: 0 0 var(--radius--sm) var(--radius--sm);
        }
    }
}

