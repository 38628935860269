:root {
    /* colors */
    --color--primary: #196d8d;
    --color--primary-light: #C9DFED;
    --color--primary-lightest: #EFF5F9;
    --color--pink: #e24585;
    --color--green: #c7d300;
    --color--light: #fff;
    --color--grey: #f4f4f4;
    --color--font-base: var(--color--primary);

    /* typography */
    --font--base-family: Roboto Condensed, sans-serif;
    --font--base-size: 16px;

    /* text-size*/
    --text--xs: 0.75rem;
    --text--sm: 0.875rem;
    --text--md: 1rem;
    --text--lg: 1.25rem;
    --text--xl: 1.5rem;
    --text--2xl: 1.75rem;
    --text--3xl: 2rem;
    --text--4xl: 2.25rem;
    --text--5xl: 3rem;
    --text--6xl: 3.75rem;
    --text--7xl: 4.5rem;
    --text--8xl: 6rem;
    --text--9xl: 8rem;

    /* leading / line-height */
    --leading--none: 1;
    --leading--tight: 1.25;
    --leading--normal: 1.5;
    --leading--relaxed: 1.75;
    --leading--loose: 2;

    /* spacing */
    --spacing--4xs: 0.125rem; /* 2px */
    --spacing--3xs: 0.25rem; /* 4px */
    --spacing--2xs: 0.5rem; /* 8px */
    --spacing--xs: 1rem; /* 16px */
    --spacing--sm: 1.5rem; /* 24px */
    --spacing--md: 2rem; /* 32px */
    --spacing--lg: 2.5rem; /* 40px */
    --spacing--xl: 3rem; /* 48px */
    --spacing--2xl: 4rem; /* 64px */
    --spacing--3xl: 4.5rem; /* 96px */
    --spacing--4xl: 5rem; /* 128px */
    --spacing--5xl: 5.5rem; /* 160px */
    --spacing--6xl: 6rem; /* 192px */
    --spacing--7xl: 6.5rem; /* 240px */
    --spacing--8xl: 7rem; /* 288px */

    /* radius */
    --radius--sm: 10px;
    /* easing */
    --easing--in-out: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    --easing--in: cubic-bezier(0.6, 0.04, 0.98, 0.335);
    --easing--out: cubic-bezier(0.075, 0.82, 0.165, 1);

    @media (--media--md) {
        --spacing--3xl: 6rem; /* 96px */
        --spacing--4xl: 8rem; /* 128px */
        --spacing--5xl: 10rem; /* 160px */
        --spacing--6xl: 12rem; /* 192px */
        --spacing--7xl: 14rem; /* 240px */
        --spacing--8xl: 16rem; /* 288px */
    }
}

/* breakpoints*/

@custom-media --media--sm (min-width: 640px);
@custom-media --media--md (min-width: 768px);
@custom-media --media--lg (min-width: 1024px);
@custom-media --media--xl (min-width: 1280px);
@custom-media --media--2xl (min-width: 1536px);

@custom-media --media--sm-max (max-width: 767px);