.pageHeaderSlider {
    width: 100%;
    position: relative;
    overflow: hidden;

    &__controls {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 100;

        &__inner {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding-bottom: var(--spacing--sm);
            grid-column: large;
            gap: var(--spacing--sm);

            @media (--media--md) {
                grid-column-start: large-start;
                grid-column-end: center-left;
                padding-bottom: var(--spacing--lg);
            }
        }

        .swiper-buttons {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: -10px;
            gap: var(--spacing--3xs);

            .swiper-button-prev, .swiper-button-next {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 36px;
                height: 36px;
                color:  var(--color--light);
                cursor: pointer;

                &:hover {
                    opacity: 0.5;
                }

                svg {
                    width: 36px;
                    height: 36px;
                }

                &.swiper-button-disabled {
                    opacity: 0.25;
                    cursor: default;

                    &:hover {
                        opacity: 0.2;
                    }
                }
            }
        }

        .swiper-pagination {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: var(--spacing--3xs);

            .swiper-pagination-bullet {
                opacity: 0.5;
                width: 12px;
                height: 12px;
                background: var(--color--light);
                border-radius: 100%;
                cursor: pointer;

                &-active {
                    opacity: 1;
                }
            }
        }
    }

    .swipper-wrapper {
        width: 100%;
    }

    .swiper-slide {
        height: auto !important;
    

        .pageHeader {
            align-items: stretch;
            width: 100%;
            height: 100%;
            color: var(--color--light);
            
            @media (--media--sm-max) {
                grid-template-rows: max-content 1fr;
            }

            @media (--media--md) {
                height: 60vh;
                max-height: 1080px;
                min-height: 550px;
            }
            &.bg--primary {
                svg {
                    fill: var(--color--primary);
                }
            }

            &.bg--primaryLight {
                color: var(--color--primary);

                svg {
                    fill: var(--color--primary-lightest);
                }
            }
            
            &.bg--pink {
                svg {
                    fill: var(--color--pink);
                }
            }
            &.bg--green {
                svg {
                    fill: var(--color--green);
                }
            }
        
            .pageHeader__content {
                display: flex;
                align-items: center;
                order: 2;
                position: relative;
                padding: var(--spacing--xl) var(--spacing--md) var(--spacing--3xl) var(--spacing--md);
                grid-column: page;
        
                @media (--media--md) {
                    grid-column-start: large-start;
                    grid-column-end: center-left;
                    order: 1;
                    padding: var(--spacing--3xl) 0;
                }

                .content__inner {
                    max-width: 575px;
                }

                svg {
                    display: block;
                    position: absolute; 
                    top: 0;
                    right: 0;
                    z-index: 1;

                    @media (--media--md) {
                        &.svg__mobile {
                            display: none;
                        }
                        &.svg__desktop {
                            display: block;
                            width: auto;
                            height: 100%;
                            bottom: 0;
                            transform: translateX(calc(100% - 1px));
                        }
                    }

                    &.svg__desktop {
                        display: none;
                    }
                    &.svg__mobile {
                        width: 100%;
                        height: auto;
                        left: 0;
                        transform: translateY(calc(-100% + 1px));
                    }
                }
            }
        
            .pageHeader__media {
                display: flex;
                align-items: stretch;
                order: 1;
                grid-column: page;
        
                @media (--media--md) {
                    grid-column-start: center-left;
                    grid-column-end: page-end;
                    order: 2;
                }
        
                .media {
                    position: relative;
                    
                    img {
                        height: 40vh;
                        max-height: 600px;
                        min-height: 350px;
                        object-fit: cover;

                        @media (--media--md) {
                            height: 100%;
                            max-height: unset;
                            min-height: unset;
                            position: absolute;
                            inset: 0;
                        }
                    }
                }
            }
        }
    }
}
