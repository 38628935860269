.text-media {
    display: grid;
    grid-template-columns: 1fr;
    align-items: stretch;
    width: 100%;
    color: var(--color--light);
    overflow: hidden;
    border-radius: var(--radius--sm);


    @media (--media--lg) {
        grid-template-columns: 1fr 1fr;
        &.mirrored {
            .text {
                order: 2;
            }
            .media {
                order: 1;
            }
        }
    }

    &.bg--none,
    &.bg--primaryLight {
        color: var(--color--primary);
    }

    .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        order: 1;
        padding: var(--spacing--md);

        @media (--media--lg) {
            padding: var(--spacing--2xl);
        }
    }

    .media {
        justify-content: center;
        align-items: stretch;
        order: 2;

        img {
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}
