.pageCardSliderItem {
    display: flex;
    flex-direction: column;
    width: 85%;
    height: auto !important;
    margin-right: var(--spacing--lg);
    overflow: hidden;
    border-radius: var(--radius--sm);

    @media (--media--md) {
        --columns: 2;
        --spacings: calc(var(--columns) - 1);
        width: calc(100% / var(--columns) - var(--spacings) / var(--columns) * var(--spacing--lg)) !important;
    }
    
    @media (--media--lg) {
        --columns: 3;
    }
    
    @media (--media--xl) {
        --columns: 4;
    }

    @media (--media--2xl) {
        --columns: 5;
    }

    &:last-child {
        margin-right: 0;
    }

    .media {
        width: 100%;

        img {
            object-fit: cover;
            object-position: center;
            aspect-ratio: 1/1;
        }
    }

    &__title {
        width: 100%;
        background: var(--color--primary-light);
        padding: var(--spacing--sm);
        text-align: center;
    }
}
