.form {
    form {
        width: 100%;
        height: auto;

        .form__content {
            display: grid;
            grid-template-columns: 1fr;
            gap: var(--spacing--sm);

            @media (--media--md) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (--media--lg) {
                grid-template-columns: repeat(4, 1fr);
            }
        }

        .form__field {
            display: flex;
            flex-direction: column;
            gap: var(--spacing--3xs);

            &.span-2 {
                @media (--media--md) {
                    grid-column: span 2;
                }
            }

            &.span-3 {
                @media (--media--lg) {
                    grid-column: span 3;
                }
            }

            &.span-4 {
                @media (--media--md) {
                    grid-column: span 2;
                }

                @media (--media--lg) {
                    grid-column: span 4;
                }
            }

            label {
                font-weight: 700;
            }
        }

        .form__input {
            padding: var(--spacing--xs);
            background: none;
            border: 1px solid var(--color--primary);
            color: var(--color--primary);
        }

        .form__select {
            padding: var(--spacing--xs);
            background: none;
            border: 1px solid var(--color--primary);
            color: var(--color--primary);
        }

        .form__checkbox {
            flex-direction: row;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            gap: var(--spacing--xs);

            span {
                display: block;
            }
        }

        a {
            font-weight: 700;
            text-decoration: underline;
            text-underline-offset: 6px;
            text-decoration-thickness: 2px;

            &:hover {
                opacity: 0.75;
            }
        }
    }

    .form__buttons {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;
        gap: var(--spacing--xs);
        margin-top: var(--spacing--lg);

        @media (--media--sm) {
            flex-direction: row;
        }
    }

    .form__button {
        width: 100%;
        padding: var(--spacing--xs) var(--spacing--md);
        font-size: var(--text--md);
        text-align: center;

        @media (--media--md) {
             width: auto;
        }
    }

    .button__submit {
        background: var(--color--primary);
        color: var(--color--light);

        &:hover {
            background: var(--color--pink);
        }
    }
}
