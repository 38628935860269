.mt-none {
    margin-top: none;
}

.mt-4xs {
    margin-top: var(--spacing--4xs);
}

.mt-3xs {
    margin-top: var(--spacing--3xs);
}

.mt-2xs {
    margin-top: var(--spacing--2xs);
}

.mt-xs {
    margin-top: var(--spacing--xs);
}

.mt-sm {
    margin-top: var(--spacing--sm);
}

.mt-md {
    margin-top: var(--spacing--md);
}

.mt-lg {
    margin-top: var(--spacing--lg);
}

.mt-xl {
    margin-top: var(--spacing--xl);
}

.mt-2xl {
    margin-top: var(--spacing--2xl);
}

.mt-3xl {
    margin-top: var(--spacing--3xl);
}

.mt-4xl {
    margin-top: var(--spacing--4xl);
}

.mt-5xl {
    margin-top: var(--spacing--5xl);
}

.mt-6xl {
    margin-top: var(--spacing--6xl);
}

.mt-7xl {
    margin-top: var(--spacing--7xl);
}

.mt-8xl {
    margin-top: var(--spacing--8xl);
}

/* SPACING BOTTOM */
.mb-none {
    margin-bottom: none;
}

.mb-4xs {
    margin-bottom: var(--spacing--4xs);
}

.mb-3xs {
    margin-bottom: var(--spacing--3xs);
}

.mb-2xs {
    margin-bottom: var(--spacing--2xs);
}

.mb-xs {
    margin-bottom: var(--spacing--xs);
}

.mb-sm {
    margin-bottom: var(--spacing--sm);
}

.mb-md {
    margin-bottom: var(--spacing--md);
}

.mb-lg {
    margin-bottom: var(--spacing--lg);
}

.mb-xl {
    margin-bottom: var(--spacing--xl);
}

.mb-2xl {
    margin-bottom: var(--spacing--2xl);
}

.mb-3xl {
    margin-bottom: var(--spacing--3xl);
}

.mb-4xl {
    margin-bottom: var(--spacing--4xl);
}

.mb-5xl {
    margin-bottom: var(--spacing--5xl);
}

.mb-6xl {
    margin-bottom: var(--spacing--6xl);
}

.mb-7xl {
    margin-bottom: var(--spacing--7xl);
}

.mb-8xl {
    margin-bottom: var(--spacing--8xl);
}
