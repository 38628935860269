.productOutput {
    width: 100%;

    .productOutput__content {
        width: 100%;
        display: grid;
        gap: var(--spacing--sm);
        grid-template-columns: 1fr;
         @media (--media--sm) {
            grid-template-columns: repeat(2, 1fr);
         }
         @media (--media--md) {
            grid-template-columns: repeat(3, 1fr);
         }
         @media (--media--lg) {
            grid-template-columns: repeat(4, 1fr);
         }
    }
}
