.text-media-expandable {

    .container {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.5s var(--easing--in-out);

        div.container--inner {
            overflow: hidden;
        }
    }

    button {
        font-weight: 700;
        text-align: left;

        @media (hover: hover) {
            &:hover {
                color: var(--color--pink);
            }
        }

        span.default {
            display: block;
        }
        span.active {
            display: none;
        }
    }

    &.active {
        button {
            span.default {
                display: none !important;
            }
            span.active {
                display: block !important;
            }
        }
        div.container {
            grid-template-rows: 1fr;
        }
    }


    &.bg--pink {
        button:hover {
            color: var(--color--green) !important;
        }
    }
}

.neos-backend {
	.text-media-expandable {
        &.active {
            div.container--inner {
                overflow: visible;
            }
        }
	}
}
