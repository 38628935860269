.text-xs {
    font-size: var(--text--xs);

    @media (--media--md) {
        font-size: var(--text--sm);
    }
}
.text-sm {
    font-size: var(--text--sm);

    @media (--media--md) {
        font-size: var(--text--md);
    }
}
.text-md {
    font-size: var(--text--md);

    @media (--media--md) {
        font-size: var(--text--lg);
    }
}
.text-lg {
    font-size: var(--text--lg);

    @media (--media--md) {
        font-size: var(--text--xl);
    }
}
.text-xl {
    font-size: var(--text--xl);

    @media (--media--md) {
        font-size: var(--text--2xl);
    }
}
.text-2xl {
    font-size: var(--text--2xl);

    @media (--media--md) {
        font-size: var(--text--3xl);
    }
}
.text-3xl {
    font-size: var(--text--3xl);

    @media (--media--md) {
        font-size: var(--text--4xl);
    }
}
.text-4xl {
    font-size: var(--text--4xl);

    @media (--media--md) {
        font-size: var(--text--5xl);
    }
}
.text-5xl {
    font-size: var(--text--5xl);

    @media (--media--md) {
        font-size: var(--text--6xl);
    }
}
.text-6xl {
    font-size: var(--text--6xl);

    @media (--media--md) {
        font-size: var(--text--7xl);
    }
}
.text-7xl {
    font-size: var(--text--7xl);

    @media (--media--md) {
        font-size: var(--text--8xl);
    }
}
.text-8xl {
    font-size: var(--text--8xl);

    @media (--media--md) {
        font-size: var(--text--9xl);
    }
}
.text-9xl {
    font-size: var(--text--9xl);

    @media (--media--md) {
        font-size: var(--text--10xl);
    }
}

