.grid-container {
    display: grid;

    /* TEMPLATE COLUMNS */
    grid-template-columns:  [page-start]
                                var(--spacing--md)
                                    [main-start large-start medium-start small-start large-inner-start medium-inner-start small-inner-start]
                                                auto
                                                    [center-left]
                                                        var(--spacing--md)
                                                            [center]
                                                        var(--spacing--md)
                                                    [center-right]
                                                auto
                                    [main-end large-end medium-end small-end large-inner-end medium-inner-end small-inner-end]
                                var(--spacing--md)
                            [page-end];

    @media (--media--sm) {
        grid-template-columns:  [page-start]
                                var(--spacing--md)
                                    [main-start large-start medium-start small-start]
                                        var(--spacing--md)
                                            [large-inner-start medium-inner-start small-inner-start]
                                                auto
                                                    [center-left]
                                                        var(--spacing--md)
                                                            [center]
                                                        var(--spacing--md)
                                                    [center-right]
                                                auto
                                            [large-inner-end medium-inner-end small-inner-end]
                                        var(--spacing--md)
                                    [main-end large-end medium-end small-end]
                                var(--spacing--md)
                            [page-end];
    }

    @media (--media--md) {
        grid-template-columns:  [page-start]
                                    1fr
                                        [main-start large-start]
                                            var(--spacing--2xl)
                                                [large-inner-start]
                                                    1fr
                                                        [medium-start]
                                                            var(--spacing--2xl)
                                                                [medium-inner-start small-start]
                                                                    var(--spacing--2xl)
                                                                        [small-inner-start]
                                                                            5fr
                                                                                [center-left]
                                                                                    var(--spacing--2xl)
                                                                                        [center]
                                                                                    var(--spacing--2xl)
                                                                                [center-right]
                                                                            5fr
                                                                        [small-inner-end]
                                                                    var(--spacing--2xl)
                                                                [medium-inner-end small-end]
                                                            var(--spacing--2xl)
                                                        [medium-end]
                                                    1fr
                                                [large-inner-end]
                                            var(--spacing--2xl)
                                        [main-end large-end]
                                    1fr
                                [page-end];
    }

    @media (--media--xl) {
        grid-template-columns:  [page-start]
                                    var(--spacing--2xl)
                                        [main-start]
                                            2fr
                                                [large-start]
                                                    var(--spacing--2xl)
                                                        [large-inner-start]
                                                            2fr
                                                                [medium-start]
                                                                    var(--spacing--2xl)
                                                                        [medium-inner-start]
                                                                             1fr
                                                                                [small-start]
                                                                                    var(--spacing--2xl)
                                                                                        [small-inner-start]
                                                                                            4fr
                                                                                                [center-left]
                                                                                                    var(--spacing--2xl)
                                                                                                        [center]
                                                                                                    var(--spacing--2xl)
                                                                                                [center-right]
                                                                                            4fr
                                                                                        [small-inner-end]
                                                                                    var(--spacing--2xl)
                                                                                [small-end]
                                                                            1fr
                                                                        [medium-inner-end]
                                                                    var(--spacing--2xl)
                                                                [medium-end]
                                                            2fr
                                                        [large-inner-end]
                                                    var(--spacing--2xl)
                                                [large-end]
                                            2fr
                                        [main-end]
                                    var(--spacing--2xl)
                                [page-end];
    }

    @media (--media--2xl) {
        grid-template-columns:  [page-start]
                                    var(--spacing--2xl)
                                        [main-start]
                                            2fr
                                            [large-start]
                                                var(--spacing--2xl)
                                                    [large-inner-start]
                                                        2fr
                                                            [medium-start]
                                                                var(--spacing--2xl)
                                                                    [medium-inner-start]
                                                                        1fr
                                                                            [small-start]
                                                                                var(--spacing--2xl)
                                                                                    [small-inner-start]
                                                                                        3fr
                                                                                            [center-left]
                                                                                                var(--spacing--2xl)
                                                                                                    [center]
                                                                                                var(--spacing--2xl)
                                                                                            [center-right]
                                                                                        3fr
                                                                                    [small-inner-end]
                                                                                var(--spacing--2xl)
                                                                            [small-end]
                                                                        1fr
                                                                    [medium-inner-end]
                                                                var(--spacing--2xl)
                                                            [medium-end]
                                                        2fr
                                                    [large-inner-end]
                                                var(--spacing--2xl)
                                            [large-end]
                                            2fr
                                        [main-end]
                                    var(--spacing--2xl)
                                [page-end];
    }

    /* GRID AREAS */
    .area- {
        &\[page\] {
            grid-column: page;
        }
        &\[main\] {
            grid-column: main;
        }
        &\[large\] {
            grid-column: large;
        }
        &\[large-inner\] {
            grid-column: large-inner;
        }
        &\[medium\] {
            grid-column: medium;
        }
        &\[medium-inner\] {
            grid-column: medium-inner;
        }
        &\[small\] {
            grid-column: small;
        }
        &\[small-inner\] {
            grid-column: small-inner;
        }
    }

     /* GRID ALIGN */
    .align- {
        &start {
            align-items: flex-start;
        }
        &end {
            align-items: flex-end;
        }
        &center {
            align-items: center;
        }
        &stretch {
            align-items: stretch;
        }
    }

    /* GRID JUSTIFY */
    .justify- {
        &start {
            justify-content: flex-start;
        }
        &end {
            justify-content: flex-end;
        }
        &center {
            justify-content: center;
        }
        &between {
            justify-content: space-between;
        }
        &arround {
            justify-content: space-around;
        }
        &evenly {
            justify-content: space-evenly;
        }
    }

}
