.gdpr-cookieconsent-container {
    --cookieconsent-theme-color: var(--color--light);
    --cookieconsent-overlay-color: rgb(0 0 0 / .5);
    --cookieconsent-bg-color: var(--color--base);

    --cookieconsent-group-bg-color: var(--color--light);

    --cookieconsent-button-text-color: var(--color--light);
    --cookieconsent-button-primary-color: var(--cookieconsent-theme-color);
    --cookieconsent-button-secondary-color: rgb(255 255 255);
    --cookieconsent-button-primary-text-color: var(--cookieconsent-button-text-color);
    --cookieconsent-button-secondary-text-color: var(--color--base);
    --cookieconsent-button-borderradius: 0;

    --cookieconsent-spacing: 1rem;
    --cookieconsent-width: 600px;

    --cookieconsent-focus-outline: transparent;
    --cookieconsent-focus-outline-offset: 0;

	flex-direction: row !important;
	justify-content: center;
    background: rgb(0 0 0 / .5);
    overflow-y: scroll;

    &::before {
        display: none;
    }

    &:focus-visible {
        outline: transparent;
    }

	.gdpr-cookieconsent-settings {
        max-width: 600px;
		position: absolute;
		top: var(--spacing--3xl);
		left: 50%;
        background-color: var(--color--primary-light) !important;
        border-radius: var(--radius--sm);
		transform: translateX(-50%);

        @media (max-width: 500px) {
            width: 90%;
        }

        @media (min-width: 501px) {
            min-width: 450px;
        }

		.hidden {
			display: none !important;
		}

		.gdpr-cookieconsent-settings__content {
			display: flex;
            flex-direction: column;
            color: var(--color--font-base);
            padding: calc(var(--spacing--md) * 0.75);
            padding-top: var(--spacing--md);

            @media (--media--sm) {
                padding: calc(var(--spacing--lg) * 0.75);
                padding-top: var(--spacing--lg);
            }

			.gdpr-cookieconsent-settings__content__info {
                display: block;
                order: 1;
				font-family: var(--font--base-family);
				hyphens: auto;
				padding-bottom: var(--spacing--md);

				h2 {
					font-size: var(--text--4xl);
                    color: var(--color--font-base);
                    text-align: center;
				}

				.gdpr-cookieconsent-settings__content__info__description {
					display: block;
                    font-family: var(--font--base-family);
					font-size: var(--text--lg);
					line-height: var(--leading--loose);
                    color: var(--color--font-base);
                    margin-bottom: var(--spacing--xs);

					p {
                        hyphens: auto;
                        text-align: center;
					}

                    &.gdpr-cookieconsent-settings__content__info__description--settings {
                        display: none;
                    }
				}
			}

            .gdpr-cookieconsent-settings__content__settings {
                order: 2;
                margin-top: 0;
                margin-bottom: var(--spacing--sm);

                .gdpr-cookieconsent-setting-group {
                    background-color: transparent !important;

                    .gdpr-cookieconsent-setting-group__details {
                        color: var(--color--font-base) !important;
                    }
                }

                input:checked {
                    background: var(--color--green) !important;
                }

                .gdpr-cookieconsent-switch--group {
                    flex-wrap: nowrap;
                    font-size: var(--text--lg);
                    hyphens: auto;
                }

                .gdpr-cookieconsent-switch--cookie {
                    flex-wrap: nowrap;
                    font-size: var(--text--md);
                    hyphens: auto;
                    color: var(--color--font-base);
                }
            }

			.gdpr-cookieconsent-settings__content__cta {
                display: flex;
                flex-direction: column;
                align-items: center;
                order: 3;
				margin: 0 !important;

				.gdpr-cc-button {
                    display: inline-block;
                    width: fit-content;
                    position: relative;
					font-size: var(--text--lg);
                    font-weight: 800;
                    color: var(--color--light);
                    background: var(--color--pink);
                    padding: 0;
                    padding: var(--spacing--2xs) var(--spacing--md);
                    margin-bottom: var(--spacing--xs);
                    border-radius: 5rem;
                    cursor: pointer;

                    @media (max-width: 500px) {
                        white-space: normal;
                    }

                    @media (hover: hover) {
                        &:hover {
                            text-decoration: underline;
                            filter: brightness(1);
                            text-underline-offset: 2px;
                            text-decoration-thickness: 2px;
                        }
                    }
				}

                .gdpr-cc-button--secondary, #gdpr-cc-btn-accept-necessary {
					font-size: var(--text--md);
                    font-weight: 500;
                    text-transform: none;
                    color: var(--color--font-base);
                    background: none !important;
                    margin: 0;
                    border: none;
                    transition: all 0.35s var(--easing--in-out);
                }
			}

            .gdpr-cookieconsent-settings__content__links {
                order: 4;
                margin-top: var(--spacing--md);
				text-align: center;

                a {
                    font-weight: 500;
                    color: var(--color--font-base);
                    transition: color 0.35s var(--easing--in-out);
                }

                span {
                    font-weight: 500;
                    color: var(--color--font-base);
                }
			}
		}
	}
}

.gdpr-cookieconsent-switch {
    --cookieconsent-switch-thumb-size: 1.25rem;
    --cookieconsent-switch-thumb-color: rgb(255 255 255);
    --cookieconsent-switch-thumb-highlight-color: rgba(0 0 0 / 0.25);
    --cookieconsent-switch-length: calc(var(--cookieconsent-switch-thumb-size) * 2);
    --cookieconsent-switch-padding: 4px;
    --cookieconsent-switch-inactive-color: var(color--grey);
    --cookieconsent-switch-active-color: var(--cookieconsent-theme-color);
    --cookieconsent-switch-thumb-size-small: 1rem;
    --cookieconsent-switch-outline: var(--cookieconsent-focus-outline);
    --cookieconsent-switch-outline-offset: var(--cookieconsent-focus-outline-offset);
}
