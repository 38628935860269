.media {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing--xs);

    img, .video {
        width: 100%;
        height: auto;
    }
}
