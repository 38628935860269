.header {
    width: 100%;
    height: 75px;
    position: sticky;
    top: 0;
    z-index: 9999;
    background: white;
    box-shadow: 0 0 30px rgba(0,0,0,0.125);

    @media (--media--md) {
        height: 97px;
    }

    .header__inner {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        gap: var(--spacing--md);

        .logo {
            display: flex;
            align-items: center;

            img {
                display: block;
                width: auto;
                height: 45px;

                @media (--media--md) {
                    width: auto;
                    height: 55px;
                }
            }
        }
    }
}
